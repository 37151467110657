
const version = '240131';

//------------------------------------------------------------------------------

const sessionType = ('; ' + document.cookie).split(`; session_type=`).pop().split(';')[0];

//------------------------------------------------------------------------------

const host = window.location.hostname;
const isPartnerSession = host.indexOf('partners.') > -1;
const isDev = host.substr(0, 3) === 'dev' || host.substr(9, 3) === 'dev';
const isLocal = ['localhost', 'bo.rcg.local', 'partners.rcg.local'].indexOf(host) > -1;

//------------------------------------------------------------------------------

let domain = 'https://rcg-dev-api.smart-labs.tech';
if (!isLocal) {
    if(isDev){
        domain = 'https://rcg-dev-api.smart-labs.tech';
    } else {
        domain = 'https://rcg-dev-api.smart-labs.tech';
    }
}
//domain = 'https://v1.api.reflexecg.eu';

//------------------------------------------------------------------------------

var config = {
    pageSize: 25,
    websiteURL: domain,
    apiURL: domain + '/v1/',
    apiEndpoint: '/v1',
    roles: ['user', 'admin'],
    date: {
        defaultFormat: 'YYYY-MM-DD',
        defaultViewFormat: 'DD/MM/YYYY',
        defaultExtendedFormat: 'YYYY-MM-DD HH:mm:ss'
    },
    defaultContentType: 'application/json;charset=UTF-8',
    defaultErrorMsg: 'Une erreur est survenue lors de traitement de votre demande.',
    taxesAPIToken: 'U47zE2vx6L5dSLREZ6ftKcXFnFnG27ENfjYcVnUymP8TBdDrNujAZjKyxA9gM5ka',
    auth: {
        token: 't' + version,
        me: 'u' + version
    },
    tmsPopup: 'showTMSParamsPopup',
    antsProcedureResumeLink: 'https://mesdemarches.siv.interieur.gouv.fr/siv-tp/#/reprise/',
    depPostalCodesLink: 'https://www.codepostal.org/##commune##.html',
    postalCodeLink: 'https://public.opendatasoft.com/explore/dataset/correspondance-code-insee-code-postal/table/?flg=fr&q=',
    idle: {
        idleDuration: 10 * 60,
        timeoutDuration: 20
    },
    fromHeader: {
        name: 'x-from',
        value: 'bo'
    },
    isPartnerSession: isPartnerSession,
    isDevicesManagerSession: sessionType === 'devices-manager',
    sessionType: sessionType,
    app: {
        name: 'managerApp',
        proceduresDir: 'app/procedures/',
        templatesDir: 'app/procedures/templates/',
        procedureTypes: {
            cim: 'certificat-immatriculation',
            ciww: 'certificat-immatriculation-ww',
            civi: 'certificat-immatriculation-vi',
            dc: 'declaration-cession',
            da: 'declaration-achat',
            cad: 'changement-adresse',
            dup: 'duplicata',
            vel: 'vente-en-ligne',
            pc: 'passcar'
        },
        procedures: {
            cim: {
                cssClass: 'cim',
                shortType: 'cim',
                type: 'certificat-immatriculation',
                instanceName: 'CertificatImmatriculation',
                listTitle: 'Démarches de changement de titulaire',
                serviceName: 'SERVICE_CERTIFICAT_IMMATRICULATION',
                hasANTS: true,
                hasTaxes: true,
                isCIM: true
            },
            ciww: {
                cssClass: 'ciww',
                shortType: 'ciww',
                type: 'certificat-immatriculation-ww',
                instanceName: 'CertificatImmatriculationWW',
                listTitle: "Démarches d'immatriculation WW",
                serviceName: 'SERVICE_CERTIFICAT_IMMATRICULATION_WW',
                hasANTS: true,
                hasTaxes: true,
                isCIWW: true
            },
            civi: {
                cssClass: 'civi',
                shortType: 'civi',
                type: 'certificat-immatriculation-vi',
                instanceName: 'CertificatImmatriculationVI',
                listTitle: "Démarches immatriculation définitive d'un véhicule importé",
                serviceName: 'SERVICE_CERTIFICAT_IMMATRICULATION_VI',
                hasANTS: true,
                hasTaxes: false,
                isCIVI: true
            },            
            dc: {
                cssClass: 'dc',
                shortType: 'dc',
                type: 'declaration-cession',
                instanceName: 'DeclarationCession',
                listTitle: 'Démarches de déclaration de cession',
                serviceName: 'SERVICE_DECLARATION_CESSION',
                hasANTS: false,
                hasTaxes: false,
                isDC: true
            },
            da: {
                cssClass: 'da',
                shortType: 'da',
                type: 'declaration-achat',
                instanceName: 'DeclarationAchat',
                listTitle: "Démarches de déclaration d'achat",
                serviceName: 'SERVICE_DECLARATION_ACHAT',
                hasANTS: false,
                hasTaxes: false,
                isDA: true
            },
            cad: {
                cssClass: 'cad',
                shortType: 'cad',
                type: 'changement-adresse',
                instanceName: 'ChangementAdresse',
                listTitle: "Démarches de changement d'adresse",
                serviceName: 'SERVICE_CHANGEMENT_ADRESSE',
                hasANTS: true,
                hasTaxes: true,
                isCAD: true
            },
            dup: {
                cssClass: 'dup',
                shortType: 'dup',
                type: 'duplicata',
                instanceName: 'Duplicata',
                listTitle: 'Démarches de demande de duplicata',
                serviceName: 'SERVICE_DUPLICATA',
                hasANTS: true,
                hasTaxes: true,
                isDUP: true
            },
            vel: {
                cssClass: 'vel',
                shortType: 'vel',
                type: 'vente-en-ligne',
                instanceName: 'vente-en-ligne',
                listTitle: 'Démarches de vente 100% en ligne (passcar)',
                serviceName: 'SERVICE_VENTE_EN_LIGNE',
                hasANTS: false,
                hasTaxes: false,
                isVEL: true,
                isVENL: true
            }
        },
        cookies: {
            itemsPerPage: 'pagination.procedures.itemsPerPage',
            pendingOnlyProcedures: 'pagination.procedures.pendingOnly',
            webappToken: 'webapp.token'
        },
        defaultErrorMessage: 'Une erreur est survenue lors du traitement de votre demande.'
    },
    versionTimestamp: version,
    version: 'rcg-bo-' + (isDev ? 'dev' : 'prod') + ' v' + version    
};
